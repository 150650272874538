import { cloneDeep } from 'lodash';

export default function appReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'GET_TEXTS': {
			newState.fetchingTexts = true;
			return newState;
		}
		case 'GET_TEXTS_FULFILLED': {
			newState.fetchingTexts = false;
			newState.texts = action.payload.texts;
			return newState;
		}
		case 'GET_TEXTS_REJECTED': {
			newState.fetchingTexts = false;
			return newState;
		}
		case 'UPDATE_TEXT': {
			newState.savingText = true;
			newState.savedText = null;
			return newState;
		}
		case 'UPDATE_TEXT_FULFILLED': {
			newState.savingText = false;
			newState.savedText = true;
			newState.texts = newState.texts.map(t => {
				if (t.id === action.payload.text.id) {
					return action.payload.text;
				}
				return t;
			});
			return newState;
		}
		case 'UPDATE_TEXT_REJECTED': {
			newState.savingText = false;
			newState.savedText = false;
			return newState;
		}
		case 'UPDATE_TERMS': {
			newState.savingTerms = true;
			newState.savedTerms = null;
			return newState;
		}
		case 'UPDATE_TERMS_FULFILLED': {
			newState.savingTerms = false;
			newState.savedTerms = true;
			newState.texts = newState.texts.map(t => {
				if (t.id === action.payload.text.id) {
					return action.payload.text;
				}
				return t;
			});
			return newState;
		}
		case 'UPDATE_TERMS_REJECTED': {
			newState.savingTerms = false;
			newState.savedTerms = false;
			return newState;
		}
		case 'UPDATE_COOKIES': {
			newState.savingCookies = true;
			newState.savedCookies = null;
			return newState;
		}
		case 'UPDATE_COOKIES_FULFILLED': {
			newState.savingCookies = false;
			newState.savedCookies = true;
			newState.texts = newState.texts.map(t => {
				if (t.id === action.payload.text.id) {
					return action.payload.text;
				}
				return t;
			});
			return newState;
		}
		case 'UPDATE_COOKIES_REJECTED': {
			newState.savingCookies = false;
			newState.savedCookies = false;
			return newState;
		}
		case 'UPDATE_PRIVACY': {
			newState.savingPrivacy = true;
			newState.savedPrivacy = null;
			return newState;
		}
		case 'UPDATE_PRIVACY_FULFILLED': {
			newState.savingPrivacy = false;
			newState.savedPrivacy = true;
			newState.texts = newState.texts.map(t => {
				if (t.id === action.payload.text.id) {
					return action.payload.text;
				}
				return t;
			});
			return newState;
		}
		case 'UPDATE_PRIVACY_REJECTED': {
			newState.savingPrivacy = false;
			newState.savedPrivacy = false;
			return newState;
		}
		case 'GET_PROFESSIONS': {
			newState.fetchingProfessions = true;
			newState.fetchedProfessions = null;
			return newState;
		}
		case 'GET_PROFESSIONS_FULFILLED': {
			newState.fetchingProfessions = false;
			newState.fetchedProfessions = true;
			newState.professions = action.payload.professions;
			return newState;
		}
		case 'GET_PROFESSIONS_REJECTED': {
			newState.fetchingProfessions = false;
			newState.fetchedProfessions = false;
			return newState;
		}
		case 'SAVE_PROFESSIONS':
		case 'UPDATE_PROFESSIONS': {
			newState.savingProfession = true;
			newState.savedProfession = true;
			return newState;
		}
		case 'SAVE_PROFESSIONS_FULFILLED':
		case 'UPDATE_PROFESSIONS_FULFILLED': {
			newState.savingProfession = false;
			newState.savedProfession = true;
			return newState;
		}
		case 'SAVE_PROFESSIONS_REJECTED':
		case 'UPDATE_PROFESSIONS_REJECTED': {
			newState.savingProfession = false;
			newState.savedProfession = false;
			return newState;
		}
		case 'RESET_SAVED_TEXT': {
			newState.savedText = false;
			return newState;
		}
		case 'RESET_PROFESSIONS_MODALS': {
			newState.savingProfession = false;
			newState.savedProfession = false;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	fetchingTexts: false,
	texts: [],
	savingText: false,
	savedText: null,
	fetchingProfessions: false,
	fetchedProfessions: null,
	professions: [],
	savingProfession: false,
	savedProfession: false,
	savingTerms: false,
	savedTerms: false,
	savingCookies: false,
	savedCookies: false,
	savingPrivacy: false,
	savedPrivacy: false
};