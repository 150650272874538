import { cloneDeep } from 'lodash';
import userPlaceholder from '../../images/user-placeholder.png';

export default function userManagementReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'GET_USER': {
			newState.fetchingUser = true;
			newState.fetchedUser = null;
			newState.fetchingUserError = null;
			return newState;
		}
		case 'GET_USER_FULFILLED': {
			newState.fetchingUser = false;
			newState.fetchedUser = true;
			newState.fetchingUserError = null;
			newState.user = action.payload.user;
			return newState;
		}
		case 'GET_USER_REJECTED': {
			newState.fetchingUser = false;
			newState.fetchedUser = false;
			return newState;
		}
		case 'RESET_PASSWORD': {
			newState.resettingPassword = true;
			newState.resetPassword = null;
			newState.resettingPasswordError = '';
			return newState;
		}
		case 'RESET_PASSWORD_FULFILLED': {
			newState.resettingPassword = false;
			newState.resetPassword = true;
			newState.resettingPasswordError = '';
			return newState;
		}
		case 'RESET_PASSWORD_REJECTED': {
			newState.resettingPassword = false;
			newState.resetPassword = false;
			return newState;
		}
		case 'SUSPEND_ACCOUNT': {
			newState.suspendingAccount = true;
			newState.suspendedAccount = null;
			newState.suspendingAccountError = '';
			return newState;
		}
		case 'SUSPEND_ACCOUNT_FULFILLED': {
			newState.suspendingAccount = false;
			newState.suspendedAccount = true;
			newState.suspendingAccountError = '';
			newState.user.banned = !newState.user.banned;
			return newState;
		}
		case 'SUSPEND_ACCOUNT_REJECTED': {
			newState.suspendingAccount = false;
			newState.suspendedAccount = false;
			return newState;
		}
		case 'DELETE_ACCOUNT': {
			newState.deletingAccount = true;
			newState.deletedAccount = null;
			newState.suspendingAccountError = '';
			return newState;
		}
		case 'DELETE_ACCOUNT_FULFILLED': {
			newState.deletingAccount = false;
			newState.deletedAccount = true;
			newState.suspendingAccountError = '';
			return newState;
		}
		case 'DELETE_ACCOUNT_REJECTED': {
			newState.deletingAccount = false;
			newState.deletedAccount = false;
			return newState;
		}
		case 'RESET_DELETE_USER_MODAL': {
			newState.deletingUser = false;
			newState.deletedUser = null;
			return newState;
		}
		case 'RESET_USER': {
			return cloneDeep(defaultState);
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	user: {},
	fetchingUser: false,
	fetchedUser: null,
	fetchingUserError: null,
	resettingPassword: false,
	resetPassword: null,
	resettingPasswordError: '',
	suspendingAccount: false,
	suspendedAccount: null,
	suspendingAccountError: '',
	deletingAccount: false,
	deletedAccount: null,
	deletingAccountError: '',
	deletingUser: false,
	deletedUser: null
};