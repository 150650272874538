import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import generateNewStore from './javascript/store.js'
import MainApp from './javascript/components/MainApp.jsx';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css'
import 'react-table/react-table.css';
import './css/main.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

const store = generateNewStore();

const rootElement = document.getElementById('root');
render((
	<Provider store={ store }>
		<BrowserRouter>
			<MainApp/>
		</BrowserRouter>
	</Provider>
), rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
