import axios from 'axios';
import { calls } from "../utils/calls.js";
import { createError } from '../utils/utils.js';
import { JAM_USERS_URL } from '../utils/config.js';

/**
 * Verify a user's account
 * @param data {Object}
 * @returns {Function}
 */
export const verifyAccount = data => {
	return dispatch => {
		dispatch({ type: 'VERIFY_ACCOUNT' });
		axios(calls.verifyAccount(JAM_USERS_URL, data)).then(() => {
			dispatch({ type: 'VERIFY_ACCOUNT_FULFILLED' });
		}).catch(err => {
			dispatch({ type: 'VERIFY_ACCOUNT_REJECTED' });
			createError(err);
		})
	}
};