import { cloneDeep } from 'lodash';
import moment from 'moment';

export default function pushNotificationsReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'SEND_NOTIFICATION': {
			newState.sendingNotification = true;
			return newState;
		}
		case 'SEND_NOTIFICATION_FULFILLED': {
			newState.sendingNotification = false;
			newState.sentNotification = true;
			return newState;
		}
		case 'SEND_NOTIFICATION_REJECTED': {
			newState.sendingNotification = false;
			return newState;
		}
		case 'RESET_NOTIFICATION_MODAL': {
			newState.sendingNotification = false;
			newState.sentNotification = false;
			return newState;
		}
		case 'GET_ADMIN_NOTIFICATIONS': {
			newState.fetchingAdminNotifications = true;
			return newState;
		}
		case 'GET_ADMIN_NOTIFICATIONS_FULFILLED': {
			const { notifications } = action.payload;
			newState.fetchingAdminNotifications = false;
			newState.adminNotifications = notifications.map(notification => ({
				...notification,
				fullNameEnglish: notification.adminStation.fullNameEnglish,
				fullNameArabic: notification.adminStation.fullNameArabic,
				title: notification.payload.en.notification.title,
				titleArabic: notification.payload.ar.notification.title,
				description: notification.payload.en.notification.body,
				descriptionArabic: notification.payload.ar.notification.body,
				sentOn: moment(notification.sentOn).format('DD.MM.YYYY HH:mm:ss')
			}));
			return newState;
		}
		case 'GET_ADMIN_NOTIFICATIONS_REJECTED': {
			newState.fetchingAdminNotifications = false;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	adminNotifications: [],
	fetchingAdminNotifications: false,
	sendingNotification: false,
	sentNotification: false
};