import { cloneDeep } from 'lodash';
import moment from 'moment';

export default function usersManagementReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'GET_USERS': {
			newState.fetchingUsers = true;
			newState.fetchedUsers = null;
			newState.fetchingUsersError = null;
			return newState;
		}
		case 'GET_USERS_FULFILLED': {
			newState.fetchingUsers = false;
			newState.fetchedUsers = true;
			newState.fetchingUsersError = null;
			newState.users = action.payload.users.map(user => ({
				...user,
				fullName: `${ user.fullNameEnglish } | ${ user.fullNameArabic }`,
				createDate: moment(user.createDate).format('DD.MM.YYYY HH:mm:ss')
			}));
			return newState;
		}
		case 'GET_USERS_REJECTED': {
			newState.fetchingUsers = false;
			newState.fetchedUsers = false;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	users: [],
	fetchingUsers: false,
	fetchedUsers: null,
	fetchingUsersError: null
};