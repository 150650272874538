import axios from 'axios';
import { calls } from "../utils/calls.js";
import { saveToken, createError } from "../utils/utils.js";
import { JAM_USERS_URL } from "../utils/config.js";

export const getCredentials = token => {
	return dispatch => {
		dispatch({ type: 'GET_CREDENTIALS' });
		dispatch({ type: 'START_LOADER', payload: { name: 'getCredentials' } });
		axios(calls.getCredentials(JAM_USERS_URL, token)).then(() => {
			dispatch({ type: 'GET_CREDENTIALS_FULFILLED' });
			dispatch({ type: 'STOP_LOADER', payload: { name: 'getCredentials' } });
		}).catch(err => {
			dispatch({ type: 'GET_CREDENTIALS_REJECTED' });
			dispatch({ type: 'STOP_LOADER', payload: { name: 'getCredentials' } });
			createError(err);
		})
	}
};

/**
 * Function to login
 * @param data {Object}
 * @param callback {Function}
 * @returns {Function}
 */
export const onLogin = (data, callback) => {
	return dispatch => {
		dispatch({ type: 'LOGIN' });
		axios(calls.adminLogin(JAM_USERS_URL, data)).then(res => {
			saveToken(res.data.data.token);
			dispatch({ type: 'LOGIN_FULFILLED', payload: { user: res.data.data.user } });
			return callback ? callback() : null;
		}).catch(err => {
			dispatch({ type: 'LOGIN_REJECTED' });
			createError(err);
		});
	};
};