import React from 'react';
import { connect } from 'react-redux';

const PageLoader = props => {
	const { layout } = props;
	return (
		<main className="page-loader-main">
			<div className="lds-spinner">
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
				<div/>
			</div>
			<p>{layout.pageLoaderText}</p>
		</main>
	)
};

const mapStateToProps = store => ({
	layout: store.layout
});

export default connect(mapStateToProps)(PageLoader);