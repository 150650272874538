import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute(props) {
	if (props.loggedIn === true) {
		return (
			<Route { ...props }/>
		)
	}
	return (
		<Redirect
			to={ {
				pathname: "/",
				state: { from: props.path }
			} }
		/>
	);
}

export default PrivateRoute;