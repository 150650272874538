import { JAM_ADMIN_URL, JAM_IMPORT_URL, JAM_USERS_URL } from './config.js';

export const calls = {
	adminLogin: (service, data) => {
		return {
			method: 'GET',
			url: `${ service }/admin-login/${ data.email || 'blank' }&${ data.password || 'blank' }`,
			headers: {
				'Content-type': 'application/json'
			}
		};
	},
	getCredentials: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/credentials`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
		};
	},
	getTexts: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/texts`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
		};
	},
	getProfessions: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/professions`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
		};
	},
	deleteProfession: (service, data, token) => {
		return {
			method: 'DELETE',
			url: `${ service }/professions/${ data }`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
		};
	},
	updateText: (service, data, token) => {
		return {
			method: 'PUT',
			url: `${ service }/texts`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
			data: data
		};
	},
	updateProfession: (service, data, token) => {
		return {
			method: 'PUT',
			url: `${ service }/professions`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
			data: data
		};
	},
	createProfession: (service, data, token) => {
		return {
			method: 'POST',
			url: `${ service }/professions`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
			data: data
		};
	},
	onSendNotification: (service, data, token) => {
		return {
			method: 'POST',
			url: `${ service }/notifications/all`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
			data: data
		};
	},
	getAdminNotifications: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/notifications/all`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			}
		};
	},
	getStations: token => {
		return {
			method: 'GET',
			url: `${ JAM_ADMIN_URL }/stations`,
			headers: {
				'Authorization': `bearer ${ token }`
			},
		};
	},
	getStation: (data, token) => {
		return {
			method: 'GET',
			url: `${ JAM_ADMIN_URL }/station/${ data }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			}
		};
	},
	saveStation: (data, token) => {
		return {
			method: 'POST',
			url: `${ JAM_ADMIN_URL }/station`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	saveUpdatedStation: (data, token) => {
		return {
			method: 'PUT',
			url: `${ JAM_ADMIN_URL }/station/${ data.id }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	importHistoricalStations: (file, token) => {
		return {
			method: 'POST',
			url: `${ JAM_IMPORT_URL }/import`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-Type': 'multipart/form-data'
			},
			data: file
		};
	},
	getAllStations: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/non-historical-stations`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-Type': 'multipart/form-data'
			}
		};
	},
	getStationMediaPromise: (service, stationId, token) => {
		return {
			method: 'GET',
			url: `${ service }/non-historical-stations/media/${ stationId }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-Type': 'multipart/form-data'
			}
		};
	},
	deleteStationMedia: (service, mediaId, token) => {
		return {
			method: 'DELETE',
			url: `${ service }/non-historical-station/media/${ mediaId }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-Type': 'multipart/form-data'
			}
		};
	},
	onDeleteStation: (data, token) => {
		return {
			method: 'DELETE',
			url: `${ JAM_ADMIN_URL }/station/${ data }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			}
		};
	},
	chooseDefaultLineage: (data, token) => {
		return {
			method: 'POST',
			url: `${ JAM_IMPORT_URL }/default-lineage`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	deployImport: (data, token) => {
		return {
			method: 'POST',
			url: `${ JAM_IMPORT_URL }/deploy`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	getLatestImport: token => {
		return {
			method: 'GET',
			url: `${ JAM_IMPORT_URL }/import`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			}
		};
	},
	updateStation: (service, data, token) => {
		return {
			method: 'PUT',
			url: `${ service }/user-station`,
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `bearer ${ token }`
			},
			data: data
		};
	},
	deleteStation: (service, data, token) => {
		return {
			method: 'DELETE',
			url: `${ service }/user-station/${ data }`,
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `bearer ${ token }`
			}
		};
	},
	getUsers: token => {
		return {
			method: 'GET',
			url: `${ JAM_ADMIN_URL }/users`,
			headers: {
				'Authorization': `bearer ${ token }`
			}
		};
	},
	getUser: (data, token) => {
		return {
			method: 'GET',
			url: `${ JAM_ADMIN_URL }/user/${ data.userId }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			}
		};
	},
	onResetUserPassword: data => {
		return {
			method: 'POST',
			url: `${ JAM_USERS_URL }/reset-password`,
			headers: {
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	onResetPassword: (data, token) => {
		return {
			method: 'POST',
			url: `${ JAM_ADMIN_URL }/reset-password`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	checkResetPasswordId: data => {
		return {
			method: 'GET',
			url: `${ JAM_USERS_URL }/reset-password/${ data.resetPasswordId }`,
			headers: {
				'Content-type': 'application/json'
			}
		};
	},
	onResetPasswordPublic: data => {
		return {
			method: 'PUT',
			url: `${ JAM_USERS_URL }/reset-password`,
			headers: {
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	suspendAccount: (data, token) => {
		return {
			method: 'PUT',
			url: `${ JAM_ADMIN_URL }/user/${ data.userId }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			},
			data: data
		};
	},
	onDeleteAccount: (data, token) => {
		return {
			method: 'DELETE',
			url: `${ JAM_ADMIN_URL }/user/${ data.userId }`,
			headers: {
				'Authorization': `bearer ${ token }`,
				'Content-type': 'application/json'
			}
		};
	},
	getFeedback: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/feedback`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
		};
	},
	getMarketingSubscriptions: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/marketing-subscriptions`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
		};
	},
	exportMarketingSubscriptions: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/marketing-subscriptions/export`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
			responseType: 'arraybuffer'
		};
	},
	exportStations: (service, token) => {
		return {
			method: 'GET',
			url: `${ service }/stations/export`,
			headers: {
				'Content-type': 'application/json',
				'Authorization': `bearer ${ token }`
			},
			responseType: 'arraybuffer'
		};
	},
	verifyAccount: (service, data) => {
		return {
			method: 'GET',
			url: `${ service }/verify-account/${ data }`,
			headers: {
				'Content-type': 'application/json'
			},
		};
	}
};