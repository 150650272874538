import React from 'react';
import { connect } from 'react-redux';

const LoaderText = props => {
	const { layout } = props;
	let lines = layout.loading.map((l, i) => (
		<p key={ i }>{ l }</p>
	));
	return (
		<div className="loader-text-wrap">
			<div className="lds-dual-ring"/>
			{ lines }
		</div>
	)
};

const mapStateToProps = store => {
	return {
		layout: store.layout
	}
};

export default connect(mapStateToProps)(LoaderText);