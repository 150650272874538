import { cloneDeep } from 'lodash';

export default function verifyAccountReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'VERIFY_ACCOUNT': {
			newState.verifyingAccount = true;
			return newState;
		}
		case 'VERIFY_ACCOUNT_FULFILLED': {
			newState.verifyingAccount = false;
			newState.verifiedAccount = true;
			return newState;
		}
		case 'VERIFY_ACCOUNT_REJECTED': {
			newState.verifyingAccount = false;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	verifyingAccount: false,
	verifiedAccount: false
};