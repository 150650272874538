import { cloneDeep } from 'lodash';

export default function userReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'GET_CREDENTIALS': {
			newState.fetchingCredentials = true;
			newState.fetchedCredentials = null;
			newState.loggedIn = null;
			return newState;
		}
		case 'GET_CREDENTIALS_FULFILLED': {
			newState.fetchingCredentials = false;
			newState.fetchedCredentials = true;
			newState.loggedIn = true;
			return newState;
		}
		case 'GET_CREDENTIALS_REJECTED': {
			newState.fetchingCredentials = false;
			newState.fetchedCredentials = false;
			newState.loggedIn = false;
			return newState;
		}
		case 'LOGIN': {
			newState.loggingIn = true;
			newState.loggedIn = null;
			return newState;
		}
		case 'LOGIN_FULFILLED': {
			newState.loggingIn = false;
			newState.loggedIn = true;
			return newState;
		}
		case 'LOGIN_REJECTED': {
			newState.loggingIn = false;
			newState.loggedIn = false;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			newState.fetchingCredentials = false;
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	fetchingCredentials: true,
	fetchedCredentials: null,
	loggingIn: false,
	loggedIn: null,
};