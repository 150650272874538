import { cloneDeep } from 'lodash';

export default function layoutReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'START_LOADER': {
			let name = action.payload.name;
			newState.loading.push(newState.loaders[name]);
			return newState;
		}
		case 'STOP_LOADER': {
			let name = action.payload.name;
			newState.loading = newState.loading.filter(l => l !== newState.loaders[name]);
			return newState;
		}
		case 'HANDLE_DRAWER': {
			let newState = cloneDeep(state);
			newState.isDrawerOpen = !state.isDrawerOpen;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	isDrawerOpen: false,
	loading: [],
	loaders: {
		getCredentials: `Sit back while we're fetching your account's details...`,
		getStations: `Fetching all available stations...`,
		getStation: `Fetching the requested station...`,
		getLatestImport: `Fetching the latest import data...`,
		getUsers: 'Fetching all users...',
		getUser: 'Fetching the requested user...',
		checkResetPasswordId: 'Checking if the link is valid...'
	},
	pageLoaderText: 'The page is loading, please wait...',
	login: {
		pageTitle: 'Jamharah Administration',
		emailPlaceholder: 'E-mail Address',
		passwordPlaceholder: 'Password',
		errorMessageTitle: 'Login Error'
	},
	resetPassword: {
		pageTitle: 'Reset Password - Jamharah',
		passwordPlaceholder: 'Password',
		confirmPasswordPlaceholder: 'Confirm Password',
		successMessageTitle: 'Success!',
		errorMessageTitle: 'Oops, there has been a problem'
	},
	sidemenu: {
		treeManagement: 'Tree Management',
		stationsManagement: 'Stations Management',
		usersManagement: 'Users Management',
		marketingSubscriptions: 'Marketing Subscriptions',
		analytics: 'Analytics',
		pushNotifications: 'Push Notifications',
		appAdministration: 'App Copywrite',
		bilingualSettings: 'Bilingual Settings',
		feedback: 'Feedback',
		logout: 'Logout'
	},
	pageTitles: [
		{
			title: 'Trees management',
			url: '/dashboard/trees-management'
		},
		{
			title: 'Stations management',
			url: '/dashboard/stations-management'
		},
		{
			title: 'Users management',
			url: '/dashboard/users-management'
		},
		{
			title: 'Marketing Subscriptions',
			url: '/dashboard/marketing-subscriptions'
		},
		{
			title: 'Analytics',
			url: '/dashboard/analytics'
		},
		{
			title: 'Push Notifications',
			url: '/dashboard/push-notifications'
		},
		{
			title: 'App Copywrite',
			url: '/dashboard/app-copywrite'
		},
		{
			title: 'Bilingual Settings',
			url: '/dashboard/bilingual-settings'
		},
		{
			title: 'Feedback',
			url: '/dashboard/feedback'
		}
	],
	generalButtons: {
		login: 'Login',
		createStation: 'Create Station',
		importStations: 'Import Stations',
		save: 'Save',
		cancel: 'Cancel',
		update: 'Update',
		importButton: 'Import',
		checkImportButton: 'Check import',
		setNewImport: 'New import',
		updateDefaultLineage: 'Update default lineage',
		confirmDefaultLineage: 'Confirm default lineage',
		deploy: 'Deploy',
		deleteButton: 'Confirm Delete',
		close: 'Close',
		confirm: 'Confirm',
		resetPassword: 'Reset Password',
		done: 'Done',
		sendNow: 'Send now',
		newNotification: 'New notification',
		newProfession: 'New profession',
		change: 'Change',
		delete: 'Delete',
		export: 'Export'
	},
	generalTexts: {
		success: 'Success',
		notificationSent: 'The notification has been successfully sent',
		accountVerified: 'Email validation successful! Check out __appStore__ all the features that are now available to you.',
		application: 'the application',
		accountVerifyError: 'The account confirmation link has expired.',
		successSuspend: 'Successfully suspended the user\'s account',
		successUnSuspend: 'Successfully unsuspended the user\'s account',
		passwordReset: 'Successfully sent the reset password email',
		importStep1Success: 'The stations have been successfully created. Proceed with Step 2 - Choose a Default Lineage.',
		importStep2Success: 'Default lineage has been chosen. Proceed with Step 3 - Deploy current data.',
		importStep3Instructions: 'When you\'re ready to deploy the import, click on the "Deploy" button.',
		error: 'Error'
	},
	treesManagement: {
		searchPlaceholder: 'Search by Station Name or Id...',
		filterBy: 'Filter by __option__',
		tableHeadings: {
			id: 'Id',
			stationName: 'Station Name',
			stationType: 'Station Type',
			completionStatus: 'Completion Status',
			type: 'Type',
			status: 'Status',
			actions: 'Actions',
			tribeNo: 'Tribe No.',
			paternalId: 'Paternal Id',
			stationLevel: 'Station Level'
		},
		standardRowValues: {
			complete: 'Complete',
			incomplete: 'Incomplete',
			showAll: 'Show All',
			pendingApproval: 'Pending Approval',
			refused: 'Refused',
			approved: 'Approved',
			historicalStation: 'Historical Station',
			familyStation: 'Family Station',
			userStation: 'User Station'
		},
		addModal: {
			createStation: 'Create Station',
			updateStation: 'Update Station',
			firstName: 'First name',
			fathersName: 'Fathers name',
			grandfathersName: 'Grandfathers name',
			lastName: 'Last name',
			fullNameEnglish: 'Full name ( English )',
			descriptionEnglish: 'Description ( English )',
			fullNameArabic: 'Full name ( Arabic )',
			descriptionArabic: 'Description ( Arabic )',
			OGNEnglish: 'Other given names ( English ) - separate with comma ","',
			OGNArabic: 'Other given names ( Arabic ) - separate with comma ","',
			tribeNameEnglish: 'Tribe name ( English )',
			tribeNameArabic: 'Tribe name ( Arabic )',
			stationLevel: 'Station level',
			gender: 'Gender',
			father: 'Father',
			stationType: 'Station type',
			male: 'Male',
			female: 'Female',
			timelineType: 'Timeline type',
			timelineTypes: [ {
				key: 'era',
				text: 'Era',
				value: 'era'
			}, {
				key: 'date',
				text: 'Date',
				value: 'date'
			} ],
			eraValues: [ {
				key: 'ancient',
				text: 'Ancient',
				value: 'ancient'
			}, {
				key: 'pre-islamic',
				text: 'Pre-Islamic',
				value: 'pre-islamic'
			}, {
				key: 'middle-ages',
				text: 'Middle-ages',
				value: 'middle-ages'
			}, {
				key: 'modern-ages',
				text: 'Modern-ages',
				value: 'modern-ages'
			} ],
			dob: 'Date of birth',
			dod: 'Date of death',
			era: 'Era',
			dateFormat: 'Use the format DD.MM.YYYY',
			selectAscendants: 'Select Ascendants'
		},
		editModal: {
			success: 'Successfully edited __fullName__'
		},
		importModal: {
			importStations: 'Import Stations',
			drag: 'Drag and Drop your file here. Accepted formats: *.json',
			defaultLineage: 'Default station',
			step1: 'Step 1 - Perform import checkup',
			step2: 'Step 2 - Choose a Default Lineage',
			step3: 'Step 3 - Deploy current data'
		},
		deleteModal: {
			deleteStation: 'Delete Station',
			confirmDelete: `Are you sure you want to delete the station "__stationName__" with the id "__id__"? This action cannot be undone.`,
			stationDeleted: 'The station has been successfully deleted!'
		}
	},
	usersManagement: {
		searchPlaceholder: 'Search by Full Name or Id',
		filterBy: 'Filter by __option__',
		tableHeadings: {
			id: 'Id',
			fullName: 'Full Name',
			emailAddress: 'E-mail Address',
			notificationsEnabled: 'Notifications enabled',
			agreedTerms: 'Agreed Terms and Conditions',
			status: 'Status',
			registeredOn: 'Registered on',
			actions: 'Actions',
			fullNameEnglish: 'Full name English',
			fullNameArabic: 'Full name Arabic',
		},
		viewModal: {
			userDetails: 'User Details'
		},
		modifyModal: {
			userActions: 'User Actions',
			resetPassword: 'Reset Password',
			suspendAccount: 'Suspend Account',
			unSuspendAccount: 'Unsuspend Account',
			deleteAccount: 'Delete Account',
			confirmAction: 'Confirm __action__',
			confirmMessage: 'Are you sure you want to __action__? Once confirmed this cannot be cancelled.',
			resetPasswordHeader: `Password Reset`,
			resetPasswordMessage: `reset __fullName__'s password`,
			suspendAccountHeader: `Suspend Account`,
			suspendAccountMessage: `suspend __fullName__'s account`,
			deleteAccountHeader: `Delete Account`,
			deleteAccountMessage: `delete __fullName__'s account`,
			thisUser: 'this user'
		},
		deleteModal: {
			deleteUser: 'Delete User',
			confirmDelete: `Are you sure you want to delete the user "__email__" with the id "__id__"? This action cannot be undone.`,
			userDeleted: 'The user has been successfully deleted!'
		}
	},
	appAdministration: {
		textsManagement: 'Texts Management',
		professionsManagement: 'Professions Management',
		terms: 'Terms and Conditions',
		cookiesPolicy: 'Cookies Policy',
		privacyPolicy: 'Privacy Policy',
		searchPlaceholder: 'Search by Station Name or Id...',
		filterBy: 'Filter by __option__',
		tableHeaders: {
			title: 'Title',
			en: 'Text in English',
			ar: 'Text in Arabic',
			actions: 'Actions',
			professionId: 'Profession id',
			professionName: 'Profession',
			professionNameArabic: 'Profession Arabic',
		},
		editModal: {
			title: 'Edit In-App Text',
			titleLabel: 'Title',
			textInEnglishLabel: 'Text in English',
			textInArabicLabel: 'Text in Arabic',
			successUpdate: 'Successfully updated the text',
			successUpdateProfession: 'Successfully updated the profession',
			profession: 'Profession',
			english: 'English',
			arabic: 'Arabic',
			savedTerms: 'Successfully updated the Terms and Conditions',
			savedCookies: 'Successfully updated the Cookies Policy',
			savedPrivacy: 'Successfully updated the Privacy Policy'
		},
		addModal: {
			title: 'Add profession',
			successCreateProfession: 'Successfully created the profession',
			profession: 'Profession'
		}
	},
	stationsManagement: {
		tableHeadings: {
			stationId: 'Station\'s id',
			stationNameEnglish: 'Station\'s Name English',
			stationNameArabic: 'Station\'s Name Arabic',
			stationType: 'Station\'s Type',
			stationOwner: 'Station\'s owner/manager',
			actions: 'Actions',
			viewMedia: 'Media',
			createdOn: 'Created on'
		},
		viewModal: {
			title: 'Now viewing __fullName__',
			editing: 'Now editing __fullName__',
			englishVersion: 'English Version',
			imagesInstructions: 'Images should be square - 720x720 or higher resolution',
			arabicVersion: 'Arabic Version',
			otherData: 'Other data',
			firstName: 'First Name',
			fatherName: 'Father Name',
			grandfatherName: 'Grand Father Name',
			lastName: 'Last Name',
			otherGivenNames: 'Other Given Names',
			gender: 'Gender',
			dob: 'Date of birth',
			dod: 'Date of death',
			alive: 'Alive',
			company: 'Company',
			profession: 'Profession',
			newOtherGivenName: 'New Other Given Name (Press Enter to add)',
			era: 'Era',
			timelineType: 'Timeline type',
			public: 'Public station'
		},
		mediaModal: {
			title: 'Viewing media for __fullName__'
		}
	},
	feedback: {
		filterBy: 'Filter by __option__',
		tableHeadings: {
			feedbackId: 'Feedback id',
			message: 'Message',
			feedbackFor: 'Feedback for',
			from: 'From',
			receivedOn: 'Received on',
			actions: 'Actions',
			on: 'On'
		},
		viewModal: {
			title: 'Feedback from __fullName__',
			messageInfo: 'Message information',
			message: 'Message',
			receivedOn: 'Received on',
			receivedFrom: 'Received from',
			id: 'Station id',
			mediaId: 'Media id',
			mediaDescriptionEnglish: 'Description English',
			mediaDescriptionArabic: 'Description Arabic',
			mediaAuthorEnglish: 'Media author (English)',
			mediaAuthorArabic: 'Media author (Arabic)',
			fullNameEnglish: 'Full name in English',
			fullNameArabic: 'Full name in Arabic',
			feedbackFor: 'Feedback for',
			last20Messages: 'Last 20 messages:',
			last10Images: 'Last 10 images:'
		}
	},
	marketingSubscriptions: {
		filterBy: 'Filter by __option__',
		tableHeadings: {
			email: 'Email Address',
			fullName: 'Full name',
			updatedOn: 'Preference updated on',
			fullNameEnglish: 'Full name English',
			fullNameArabic: 'Full name Arabic',
		},
	},
	pushNotifications: {
		notifyAllUsers: 'Notify all users',
		searchPlaceholder: 'Search by Station Name or Id...',
		filterBy: 'Filter by __option__',
		tableHeaders: {
			title: 'Title English',
			titleArabic: 'Title Arabic',
			description: 'Body English',
			descriptionArabic: 'Body Arabic',
			sentToNUsers: 'Sent to (users)',
			sentOn: 'Sent on',
			fullNameEnglish: 'Full name English',
			fullNameArabic: 'Full name Arabic',
		},
		addModal: {
			modalTitle: 'Send a new notification to all users',
			titleEnglish: 'Notification title English',
			descriptionEnglish: 'Notification description English',
			titleArabic: 'Notification title Arabic',
			descriptionArabic: 'Notification description Arabic'
		}
	},
};