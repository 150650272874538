import { cloneDeep } from 'lodash';
import moment from 'moment';

export default function feedbackReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'GET_FEEDBACK': {
			newState.fetchingFeedback = true;
			return newState;
		}
		case 'GET_FEEDBACK_FULFILLED': {
			newState.fetchingFeedback = false;
			newState.allFeedback = action.payload.allFeedback.map(f => ({
				...f,
				...f.feedback,
				date: moment(f.feedback.date).format('DD.MM.YYYY HH:mm:ss'),
				from: `${ f.station.fullNameEnglish } | ${ f.station.fullNameArabic }`
			}));
			return newState;
		}
		case 'GET_FEEDBACK_REJECTED': {
			newState.fetchingFeedback = false;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	allFeedback: [],
	fetchingFeedback: false
};