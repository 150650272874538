import { combineReducers } from 'redux';
import userReducer from './reducers/userReducer.js';
import layoutReducer from './reducers/layoutReducer.js';
import stationsReducer from './reducers/stationsReducer.js';
import stationReducer from './reducers/stationReducer.js';
import usersManagementReducer from './reducers/usersManagementReducer.js';
import userManagementReducer from './reducers/userManagementReducer.js';
import resetPasswordReducer from './reducers/resetPasswordReducer.js';
import appReducer from './reducers/appReducer.js';
import feedbackReducer from './reducers/feedbackReducer.js';
import marketingSubscriptionsReducer from './reducers/marketingSubscriptionsReducer.js';
import pushNotificationsReducer from './reducers/pushNotificationsReducer.js';
import verifyAccountReducer from './reducers/verifyAccountReducer.js';

export const createRootReducer = () => combineReducers({
	user: userReducer,
	layout: layoutReducer,
	stations: stationsReducer,
	station: stationReducer,
	usersManagement: usersManagementReducer,
	userManagement: userManagementReducer,
	resetPassword: resetPasswordReducer,
	app: appReducer,
	feedback: feedbackReducer,
	marketingSubscriptions: marketingSubscriptionsReducer,
	pushNotifications: pushNotificationsReducer,
	verifyAccount: verifyAccountReducer
});
