import { cloneDeep } from 'lodash';
import moment from 'moment';

export default function marketingSubscriptionsReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'GET_MARKETING_SUBSCRIPTIONS': {
			newState.fetchingMarketingSubscriptions = true;
			return newState;
		}
		case 'GET_MARKETING_SUBSCRIPTIONS_FULFILLED': {
			newState.fetchingMarketingSubscriptions = false;
			newState.marketingSubscriptions = action.payload.marketingSubscriptions.map(ms => ({
				...ms,
				email: ms.user.email,
				fullName: `${ ms.station.fullNameEnglish } | ${ ms.station.fullNameArabic }`,
				fullNameEnglish: ms.station.fullNameEnglish,
				fullNameArabic: ms.station.fullNameArabic,
				date: moment(ms.date).format('DD.MM.YYYY HH:mm:ss')
			}));
			return newState;
		}
		case 'GET_MARKETING_SUBSCRIPTIONS_REJECTED': {
			newState.fetchingMarketingSubscriptions = false;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultState = {
	marketingSubscriptions: [],
	fetchingMarketingSubscriptions: false
};