import { cloneDeep } from 'lodash';

export default function resetPasswordReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'UPDATE_RESET_PASSWORD': {
			newState.resetPassword[action.payload.field] = action.payload.value;
			return newState;
		}
		case 'RESET_PASSWORD_PUBLIC': {
			newState.resettingPassword = true;
			newState.resettingPasswordError = '';
			newState.resettingPasswordErrors = [];
			newState.resettingPasswordMessage = '';
			return newState;
		}
		case 'RESET_PASSWORD_PUBLIC_FULFILLED': {
			newState.resettingPassword = false;
			newState.resettingPasswordError = '';
			newState.resettingPasswordMessage = action.payload.message;
			return newState;
		}
		case 'RESET_PASSWORD_PUBLIC_VALIDATION_ERRORS': {
			return newState;
		}
		case 'RESET_PASSWORD_PUBLIC_REJECTED': {
			newState.resettingPassword = false;
			newState.resettingPasswordMessage = '';
			return newState;
		}
		case 'CHECK_RESET_PASSWORD_ID_PUBLIC': {
			newState.checkingLinkValidity = true;
			newState.linkIsValid = false;
			return newState;
		}
		case 'CHECK_RESET_PASSWORD_ID_PUBLIC_FULFILLED': {
			newState.checkingLinkValidity = false;
			newState.linkIsValid = true;
			return newState;
		}
		case 'CHECK_RESET_PASSWORD_ID_PUBLIC_REJECTED': {
			newState.checkingLinkValidity = false;
			newState.linkIsValid = false;
			return newState;
		}
		case 'RESET_RESET_PASSWORD': {
			newState.resetPassword = cloneDeep(defaultResetPassword);
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultResetPassword = {
	password: '',
	confirmPassword: ''
};

const defaultState = {
	resetPassword: cloneDeep(defaultResetPassword),
	resettingPassword: false,
	resettingPasswordError: '',
	resettingPasswordErrors: [],
	resettingPasswordMessage: '',
	checkingLinkValidity: false,
	linkIsValid: false
};