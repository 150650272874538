import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PageLoader from './Templates/PageLoader.jsx';
import { getCredentials } from '../actions/userActions.js';
import LoaderText from './Templates/LoaderText.jsx';
import PrivateRoute from './Templates/PrivateRoute.jsx';
import { checkMobileBrowser, getToken } from '../utils/utils.js';
import { ToastContainer } from 'react-toastify';
import VerifyAccount from './VerifyAccount/VerifyAccount.jsx';

const Dashboard = lazy(() => import('./Dashboard/Dashboard.jsx'));
const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword.jsx'));
const Authentication = lazy(() => import('./Authentication/Authentication.jsx'));

class MainApp extends Component {
	componentDidMount() {
		const { history, dispatch } = this.props;
		const token = getToken();
		if (token) {
			dispatch(getCredentials(token, history));
		} else {
			dispatch({ type: 'GET_CREDENTIALS_REJECTED' });
		}
	}

	_getCredentialsCallback = () => {

	};

	render() {
		const { user, layout } = this.props;
		return (
			<>
				{ user.fetchingCredentials ?
					<LoaderText/>
					:
					<>
						<main
							className={ `main-jam${ layout.isDrawerOpen && !checkMobileBrowser() ? ' drawer-open' : '' }` }>
							<Suspense fallback={ <PageLoader/> }>
								<Switch>
									<PrivateRoute
										loggedIn={ user.loggedIn }
										path={ `/dashboard` }
										component={ Dashboard }/>
									<Route path={ `/reset-password/:resetPasswordId` } component={ ResetPassword }/>
									<Route path={ `/verify-account/:activationId` } component={ VerifyAccount }/>
									<Route exact path={ `/` } component={ Authentication }/>
									<Redirect to={ `/` }/>
								</Switch>
							</Suspense>
						</main>
					</>
				}
				<ToastContainer
					position="top-right"
					autoClose={ 5000 }
					hideProgressBar={ false }
					newestOnTop={ false }
					closeOnClick
					rtl={ false }
					pauseOnVisibilityChange
					draggable
					pauseOnHover/>
			</>
		);
	}
}

const mapStateToProps = store => ({
	user: store.user,
	layout: store.layout
});

export default withRouter(connect(mapStateToProps)(MainApp));
