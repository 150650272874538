import { cloneDeep } from 'lodash';
import moment from 'moment';

export default function stationsReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch (action.type) {
		case 'GET_TREES': {
			newState.fetchingStations = true;
			newState.fetchedStations = null;
			return newState;
		}
		case 'GET_TREES_FULFILLED': {
			newState.fetchingStations = false;
			newState.fetchedStations = true;
			newState.stations = action.payload.stations;
			return newState;
		}
		case 'GET_TREES_REJECTED': {
			newState.fetchingStations = false;
			newState.fetchedStations = false;
			newState.fetchingErrors = action.payload.fetchingErrors;
			return newState;
		}
		case 'SET_IMPORT_STATIONS_FILE': {
			newState.importStations.importStationsFile = action.payload.file;
			newState.importStations.setImportFileAccepted = action.payload.message;
			newState.importStations.setImportFileError = '';
			return newState;
		}
		case 'SET_IMPORT_STATIONS_FILE_REJECTED': {
			newState.importStations.setImportFileAccepted = '';
			return newState;
		}
		case 'IMPORT_STATIONS': {
			newState.importStations.importingStations = true;
			return newState;
		}
		case 'IMPORT_STATIONS_FULFILLED': {
			newState.importStations.importingStations = false;
			newState.importStations.importedStations = true;
			newState.importStations.importErrorMessage = '';
			newState.importStations.defaultStationOptions = action.payload.defaultStationOptions;
			newState.importStations.importId = action.payload.importData.id;
			newState.importStations.importErrors = action.payload.importData.importErrors;
			newState.importStations.importWarnings = action.payload.importData.importWarnings;
			return newState;
		}
		case 'IMPORT_STATIONS_REJECTED': {
			newState.importStations.importingStations = false;
			newState.importStations.importedStations = false;
			newState.importStations.importMessage = '';
			return newState;
		}
		case 'CHOOSE_DEFAULT_LINEAGE': {
			newState.importStations.choosingDefaultLineage = true;
			return newState;
		}
		case 'CHOOSE_DEFAULT_LINEAGE_FULFILLED': {
			newState.importStations.choosingDefaultLineage = false;
			newState.importStations.choseDefaultLineage = true;
			return newState;
		}
		case 'CHOOSE_DEFAULT_LINEAGE_REJECTED': {
			newState.importStations.choosingDefaultLineage = false;
			newState.importStations.choseDefaultLineage = false;
			return newState;
		}
		case 'DEPLOY_IMPORT': {
			newState.importStations.deployingImport = true;
			return newState;
		}
		case 'DEPLOY_IMPORT_FULFILLED': {
			newState.importStations.deployingImport = false;
			newState.importStations.deployedImport = true;
			return newState;
		}
		case 'DEPLOY_IMPORT_REJECTED': {
			newState.importStations.deployingImport = false;
			newState.importStations.deployedImport = false;
			return newState;
		}
		case 'GET_LATEST_IMPORT': {
			newState.importStations.fetchingLatestImport = true;
			return newState;
		}
		case 'GET_LATEST_IMPORT_FULFILLED': {
			newState.importStations.fetchingLatestImport = false;
			newState.importStations.fetchedLatestImport = true;
			newState.importStations.defaultStationOptions = action.payload.defaultStationOptions;
			if (action.payload.latestImport) {
				newState.importStations.latestImport = action.payload.latestImport;
				newState.importStations.importId = action.payload.latestImport.id;
				if (newState.importStations.latestImport.step1) {
					newState.importStations.importedStations = true;
				}
				if (newState.importStations.latestImport.step2) {
					newState.importStations.choseDefaultLineage = true;
				}
				if (newState.importStations.latestImport.step3) {
					newState.importStations.deployedImport = true;
				}
			}
			return newState;
		}
		case 'GET_LATEST_IMPORT_REJECTED': {
			newState.importStations.fetchingLatestImport = false;
			newState.importStations.fetchedLatestImport = false;
			return newState;
		}
		case 'SET_NEW_IMPORT': {
			newState.importStations = cloneDeep(defaultImportStations);
			return newState;
		}
		case 'GET_NON_HISTORICAL_STATIONS': {
			newState.fetchingNonHistoricalStations = true;
			return newState;
		}
		case 'GET_NON_HISTORICAL_STATIONS_FULFILLED': {
			newState.fetchingNonHistoricalStations = false;
			newState.nonHistoricalStations = action.payload.stations.map(s => ({
				...s,
				timeline: s.timeline ? {
					...s.timeline,
					value: s.timeline.value ? {
						...s.timeline.value,
						dateOfBirth: moment(s.timeline.value.dateOfBirth).format('YYYY-MM-DD'),
						dateOfDeath: moment(s.timeline.value.dateOfDeath).format('YYYY-MM-DD'),
					} : {}
				} : s.timeline,
				createDate: moment(s.createDate).format('DD.MM.YYYY HH:mm:ss')
			}));
			return newState;
		}
		case 'GET_NON_HISTORICAL_STATIONS_REJECTED': {
			newState.fetchingNonHistoricalStations = false;
			return newState;
		}
		case 'UPDATE_STATION_FULFILLED': {
			const { savedStation } = action.payload;
			newState.nonHistoricalStations = newState.nonHistoricalStations.map(s => {
				if (s.id === savedStation.id) {
					console.log(savedStation.createDate);
					return {
						...savedStation,
						createDate: moment(savedStation.createDate).format('DD.MM.YYYY HH:mm:ss')
					};
				}
				return s;
			});
			return newState;
		}
		case 'RESET_IMPORT_STATIONS': {
			newState.importStations = cloneDeep(defaultImportStations);
			return newState;
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultImportStations = {
	importStationsFile: {},
	importingStations: false,
	importedStations: null,
	setImportFileAccepted: '',
	setImportFileError: '',
	importMessage: '',
	importErrorMessage: '',
	choosingDefaultLineage: false,
	choseDefaultLineage: false,
	defaultStationOptions: [],
	importId: null,
	deployingImport: false,
	deployedImport: false,
	fetchingLatestImport: false,
	fetchedLatestImport: false,
	latestImport: {},
	importErrors: [],
	importWarnings: []
};

const defaultState = {
	stations: [],
	fetchingStations: false,
	fetchedStations: null,
	fetchingErrors: [],
	importStations: cloneDeep(defaultImportStations),
	nonHistoricalStations: [],
	fetchingNonHistoricalStations: false
};