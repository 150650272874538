import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import LoaderText from '../Templates/LoaderText.jsx';
import { Grid, Message } from 'semantic-ui-react';
import { verifyAccount } from '../../actions/verifyAccountActions.js';

class VerifyAccount extends PureComponent {
	componentDidMount() {
		const { match, dispatch } = this.props;
		dispatch(verifyAccount(match.params && match.params.activationId ? match.params.activationId : ''));
	}

	render() {
		const { layout, verifyAccount } = this.props;
		if (verifyAccount.verifyingAccount) {
			return (<LoaderText/>);
		} else {
			return (
				<Grid textAlign='center' style={ { height: '100vh' } } verticalAlign='middle'>
					<Grid.Column style={ { maxWidth: 450 } }>
						<Message
							success={ verifyAccount.verifiedAccount }
							error={ !verifyAccount.verifiedAccount && !verifyAccount.verifyingAccount }
							header={ verifyAccount.verifiedAccount ? layout.generalTexts.success : layout.generalTexts.error }
							content={ () => {
								if (verifyAccount.verifiedAccount) {
									return (
										<p>{ layout.generalTexts.accountVerified.split('__appStore__')[0] }
											<a href="#">{ layout.generalTexts.application }
											</a>
											&nbsp;
											{ layout.generalTexts.accountVerified.split('__appStore__')[1] }
										</p>
									);
								} else {
									return (
										<p>
											{ layout.generalTexts.accountVerifyError }
										</p>
									);
								}
							} }
						/>
					</Grid.Column>
				</Grid>
			);
		}
	}
}

const mapStateToProps = store => ({
	layout: store.layout,
	verifyAccount: store.verifyAccount
});

export default connect(mapStateToProps)(VerifyAccount);