import { cloneDeep } from 'lodash';

export default function stationReducer(state = cloneDeep(defaultState), action) {
	let newState = cloneDeep(state);
	switch(action.type) {
		case 'UPDATE_STATION': {
			const { field, value, extraData } = action.payload;
			if (extraData.language) {
				newState.station[action.payload.field][extraData.language] = value;
			} else {
				newState.station[action.payload.field] = value;
			}
			newState.savingErrors = newState.savingErrors.filter(e => e.field !== field);
			return newState;
		}
		case 'GET_STATION': {
			newState.fetchingStation = true;
			newState.fetchedStation = null;
			newState.fetchingError = [];
			return newState;
		}
		case 'GET_STATION_FULFILLED': {
			const { station } = action.payload;

			newState.fetchingStation = false;
			newState.fetchedStation = true;
			newState.station = {
				...station,
				...station.timeline
			};
			newState.fetchingError = [];
			return newState;
		}
		case 'GET_STATION_REJECTED': {

			newState.fetchingStation = false;
			newState.fetchedStation = false;
			//newState.fetchingError = action.payload.fetchingError;
			// temporary error
			newState.fetchingError = 'The station does not exist.';
			return newState;
		}
		case 'SAVE_STATION': {

			newState.savingStation = true;
			newState.savedStation = null;
			newState.savingErrors = [];
			return newState;
		}
		case 'SAVE_STATION_FULFILLED': {

			newState.savingStation = false;
			newState.savedStation = true;
			newState.savingErrors = [];
			return newState;
		}
		case 'SAVE_STATION_REJECTED': {

			newState.savingStation = false;
			newState.savedStation = false;
			return newState;
		}
		case 'SAVE_UPDATED_STATION': {

			newState.savingStation = true;
			newState.savedStation = null;
			newState.savingErrors = [];
			return newState;
		}
		case 'SAVE_UPDATED_STATION_FULFILLED': {

			newState.savingStation = false;
			newState.savedStation = true;
			newState.savingErrors = [];
			return newState;
		}
		case 'SAVE_UPDATED_STATION_REJECTED': {

			newState.savingStation = false;
			newState.savedStation = false;
			return newState;
		}
		case 'DELETE_STATION': {

			newState.deletingStation = true;
			newState.deletedStation = null;
			newState.deleteErrors = [];
			return newState;
		}
		case 'DELETE_STATION_FULFILLED': {

			newState.deletingStation = false;
			newState.deletedStation = true;
			newState.deleteErrors = [];
			return newState;
		}
		case 'DELETE_STATION_REJECTED': {

			newState.deletingStation = false;
			newState.deletedStation = false;
			return newState;
		}
		case 'HANDLE_STATIONS_MODAL': {
			return {
				...state,
				modalOpen: !state.modalOpen
			}
		}
		case 'UPDATE_STATION_START': {
			newState.updatingStation = true;
			newState.updatedStation = null;
			return newState;
		}
		case 'UPDATE_STATION_FULFILLED': {
			newState.updatingStation = false;
			newState.updatedStation = true;
			return newState;
		}
		case 'UPDATE_STATION_REJECTED': {
			newState.updatingStation = false;
			newState.updatedStation = false;
			return newState;
		}
		case 'RESET_STATION': {
			return cloneDeep(defaultState);
		}
		case 'RESET_EVERYTHING': {
			newState = cloneDeep(defaultState);
			return newState;
		}
		default:
			return state;
	}
};

const defaultStation = {
	fullName: {
		en: '',
		ar: ''
	},
	description: {
		en: '',
		ar: ''
	},
	otherGivenNames: {
		en: '',
		ar: ''
	},
	tribeName: {
		en: '',
		ar: ''
	},
	paternalId: '',
	photo: '',
	stationLevel: '',
	timelineType: 'era',
	era: 'ancient',
	dob: '',
	dod: ''
};

const defaultState = {
	station: cloneDeep(defaultStation),
	fetchingStation: false,
	fetchedStation: null,
	fetchingError: [],
	savingStation: false,
	savedStation: null,
	savingErrors: [],
	deletingStation: false,
	deletedStation: null,
	deleteErrors: [],
	modalOpen: false,
	negativeOptions: [],
	updatingStation: false,
	updatedStation: null
};